<template>
  <div id="wrapper-avatar"
       :data-type="type"
       :style="wrapper_style"
       :class="{'sidebar': sidebar}"
  >
    <div id="avatar">
      <div id="avatar-inner"
           :data-gender="gender"
           :data-nickname="short_nickname"
           :style="style"
      >
        <ImageFadeIn
          :src="avatar_src"
        />
      </div>
    </div>
    <ImageFadeIn
      :src="flair_src"
      :height="flair_size"
      :width="flair_size"
      :style="{
        bottom: (flair_size > 20 ? '-15px' : '-10%'),
        left: (flair_size > 20 ? '-15px' : '-10%'),
      }"
    />
  </div>
</template>

<script>
import ImageFadeIn from '@/components/ImageFadeIn';

export default {
  name: 'Avatar',
  components: {
    ImageFadeIn,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'user',
    },
    height: {
      type: Number,
      required: true,
    },
    avatar_src: {
      type: String,
      required: false,
      default: '',
    },
    flair_size: {
      type: Number,
      required: false,
      default: 12,
    },
    flair_src: {
      type: String,
      required: false,
      default: '',
    },

    // only user
    gender: {
      type: Number,
      required: false,
    },
    nickname: {
      type: String,
      required: false,
      default: '',
    },
    sidebar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      short_nickname: this.$helper.getAvatarNickname(this.nickname),
    }
  },
  computed: {
    style() {
      function calcFontSize(height) {
        let fsize = Math.floor(height * .22);
        if (fsize < 10) {
          return 10;
        }
        return fsize;
      }

      return {
        lineHeight: this.height + 'px',
        fontSize: calcFontSize(this.height) + 'px',
      };
    },
    wrapper_style() {
      let style = {
        height: this.height + 'px',
        width: this.height + 'px',
      };

      if (this.type === 'room') {
        style.backgroundColor = '#DDFDDB';
      }

      return style;
    },
  },
  methods: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#wrapper-avatar {
  position: relative;
  border-radius: 100%;
}

#avatar {
  height: 100%;
  width: 100%;
  border-radius: 100%;
  overflow: hidden;
}

#wrapper-avatar.sidebar {
  border-radius: 30px;
  margin: 0 auto;
  top: 5px;
}

#avatar-inner {
  position: relative;
  height: 100%;
  margin: 0 -12%;
  text-align: center;
}

#wrapper-avatar #avatar-inner:before {
  content: attr(data-nickname);
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  font-size: inherit;
  line-height: inherit;
  text-align: center;
}
#wrapper-avatar[data-type="room"] #avatar-inner:before {
  color: #9CE8AF;
}

#avatar-inner > img {
  position: relative;
  height: 100%;
}

#wrapper-avatar > img {
  position: absolute;
  bottom: 0;
}
</style>

<template>
  <div id="room-info-container">
    <div id="wrapper-menu-avatar" :class="subscription">
      <Avatar
        type="room"
        :height="202"
        :avatar_src="roomAvatar"
        :flair_size="48"
        :flair_src="flair_src"
        :nickname="userNickname"
        :sidebar="true"
      />
      <div v-show="upload_available" id="menu-avatar-change" @click="modalUploadAvatar">
        <svg width="46" height="38" xmlns="http://www.w3.org/2000/svg">
          <path fill="#fff" fill-rule="evenodd" d="M10.723 6.1A8.003 8.003 0 0 1 18.477.078h8.802A8.003 8.003 0 0 1 35.033 6.1H40a6 6 0 0 1 6 6v18.976a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V12.1a6 6 0 0 1 6-6h4.723zM23 32.078c6.075 0 11-4.925 11-11s-4.925-11-11-11-11 4.925-11 11 4.925 11 11 11zm0-3a8 8 0 1 1 0-16 8 8 0 0 1 0 16z" />
        </svg>
      </div>
    </div>

    <sidebar-nickname :nickname="userNickname"
                      :alevel="alevel"
                      :show-achievement="show_achievement"
                      :color="color"
    />
  </div>
</template>

<script>
import Avatar from '@/components/Avatar';
import SidebarNickname from "@/profiles/components/ProfileSidebar/SidebarNickname";

export default {
  name: "SidebarRoomInfo",
  components: {
    Avatar,
    SidebarNickname
  },
  props: {
    show_achievement: {
      type: Boolean,
      required: false,
      default: true
    },
    upload_available: {
      type: Boolean,
      required: false,
      default: true
    },
    room_info: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      subscription: this.$CONSTANTS.getRoomSubscriptionParam(this.room_info.tier_id, 'name'),
      alevel: this.room_info.alevel.toString(),
    }
  },
  computed: {
    userNickname() {
      if (!this.upload_available) {
        return this.room_info.nickname;
      }
      return this.$store.state.nickname;
    },
    roomAvatar() {
      if (!this.upload_available) {
        return this.$helper.getImageURL(this.room_info.avatar_id);
      }
      return this.$store.state.avatar_base64 || this.$helper.getImageURL(this.$store.state.avatar_id);
    },
    color() {
      return this.$store.state.color ? this.$store.state.color : (this.room_info.color || '')
    },
    flair_src() {
      let flair_id = this.$store.state.flair_id ? this.$store.state.flair_id : this.room_info.flair_id;
      return this.$helper.getImageURL(flair_id, 'flair');
    }
  },
  methods: {
    modalUploadAvatar() {
      if (!this.upload_available) {
        return;
      }
      this.$store.dispatch('openModal', {
        header: 'Upload avatar',
        content: 'UploadAvatar',
        options: {
          image_current: this.roomAvatar,
          formats: [
            'jpg',
            'jpeg',
            'png',
          ],
          resolutions: {
            width: 800,
            height: 800,
          }
        },
        close_button: true,
        result: '',
      });
    },
  }
}
</script>

<style scoped>
#wrapper-menu-avatar {
  position: relative;
  height: 212px;
  width: 212px;
  margin: -76px auto 0;
  border-radius: 34px;
  box-shadow: 0 5px 7px 0 #e5e5e5;
  background-color: #fff;
}
#wrapper-menu-avatar.green {
  background-color: #59EC83;
}
#wrapper-menu-avatar.purple {
  background-color: #CA93E7;
}
#wrapper-menu-avatar.gold {
  background: linear-gradient(315deg, #FFBF00 0%, #F7F5BD 100%);
}
#wrapper-menu-avatar.platinum {
  background: linear-gradient(315deg, #ACBDC7 0%, #F0F4F6 100%);
}
#wrapper-menu-avatar.diamond {
  background: linear-gradient(315deg, #2A91A7 0%, #D4F7FF 100%);
}
#wrapper-menu-avatar.plus {
  background: linear-gradient(315deg, #2A91A7 0%, #D4F7FF 100%);
}
#wrapper-menu-avatar.vip {
  background: linear-gradient(315deg, #E54D00 0%, #FFA3B8 100%);
}
#menu-avatar-change {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 30px;
  font-size: 0;
  background-color: rgba(255, 255, 255, .01);
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}
#menu-avatar-change:before {
  content: '';
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}
#menu-avatar-change > * {
  display: inline-block;
  vertical-align: middle;
  opacity: 0;
  transition: .2s;
}
#menu-avatar-change:hover > * {
  opacity: 1;
}
</style>
<style>
#wrapper-menu-avatar #avatar {
  border-radius: 30px;
}
</style>

<template>
  <div id="room-public-profile">
    <div id="sidebar-wrapper">
      <div id="sidebar-info">
        <SidebarRoomInfo
          :room_info="shortInfo"
          :show_achievement="true"
          :upload_available="false"
        />
        <SubscriptionBadge :tier_id="shortInfo.tier_id" />
        <div id="sidebar-add-info">
          <div class="add-info-item">
            <h3>CURRENT MEMBERS</h3>
            <p>{{ $helper.commaString(addInfo.users_online) }}</p>
          </div>
          <div class="add-info-item">
            <h3>OPEN CAMS</h3>
            <p>{{ $helper.commaString(addInfo.users_on_cams) }}</p>
          </div>
          <div class="add-info-item">
            <h3>FOLLOWERS</h3>
            <p>{{ $helper.commaString(addInfo.followers) }}</p>
          </div>
          <div class="add-info-item">
            <h3>CATEGORY</h3>
            <p>{{ addInfo.category }}</p>
          </div>
          <div class="add-info-item">
            <h3>LANGUAGE</h3>
            <p>{{ addInfo.language }}</p>
          </div>
          <!--          <div class="add-info-item">
            <h3>LIVE SINCE</h3>
            <p>{{ addInfo.live_since }}</p>
          </div>-->
          <div id="topics" class="add-info-item align-left">
            <h3>TOPICS</h3>
            <div class="align-center">
              <Topic v-for="(topic, index) in addInfo.topics.value"
                     :key="index"
                     :name="topic"
                     :editable="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="sidebar-links">
        <a class="sidebar-link" :href="roomJoinLink">
          <img src="@/assets/images/icons/sidebar-room-enter.svg" alt="icon-room-enter">
          <span>Enter Room</span>
        </a>
        <a class="sidebar-link" :href="roomPromoteLink">
          <img src="@/assets/images/icons/sidebar-room-promote.svg" alt="icon-room-promote">
          <span>Promote Room</span>
        </a>
        <a class="sidebar-link" :href="roomGiveGiftLink">
          <img src="@/assets/images/icons/sidebar-givegift.svg" alt="icon-room-givegift">
          <span>Send Gift to Room</span>
        </a>
      </div>
    </div>
    <div id="container-wrapper">
      <WhatsHopping :value="hopping" :disabled="true" />
      <div id="container">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarRoomInfo from "@/components/room_settings/SidebarRoomInfo";
import WhatsHopping from "@/components/WhatsHopping";
import Topic from "@/components/room_settings/Topic";
import SubscriptionBadge from "@/components/room_settings/SubscriptionBadge";

export default {
  name: "RoomPublicProfile",
  components: {
    SubscriptionBadge,
    Topic,
    WhatsHopping,
    SidebarRoomInfo
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'roomProfile',
    ]),
    shortInfo() {
      return this.roomProfile.short_info;
    },
    addInfo() {
      return this.roomProfile.add_info;
    },
    hopping() {
      return this.roomProfile.hopping.value;
    },
    roomJoinLink() {
      return 'camfrog://join_room/?name=' + encodeURIComponent(this.shortInfo.nickname);
    },
    roomPromoteLink() {
      return this.$helper.getStoreUrl(this.shortInfo.nickname) + '#room_upgrades=room_promotion';
    },
    roomGiveGiftLink() {
      return this.$helper.getStoreGiftsUrl(this.shortInfo.nickname);
    }
  },
  created() {
    let data = this.$external.data.public_profile; //todo: request from API
    this.$store.commit('setRoomProfile', data);
  }
}
</script>

<style scoped>
#room-public-profile {
  display: flex;
  width: 100%;
  max-width: 1300px;
  flex-direction: row;
  justify-content: center;
  align-content: stretch;
  margin: 0 auto;
  padding: 30px 30px 50px;
  box-sizing: border-box;
}
#sidebar-wrapper {
  position: relative;
  min-width: 300px;
  max-width: 300px;
  margin: 70px 20px 0 0;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
  height: 100%;
}
#sidebar-info {
  padding: 0 30px 25px;
  box-sizing: border-box;
  text-align: center;
}
#container-wrapper {
  position: relative;
  width: 100%;
  max-width: 688px;
  min-width: 350px;
}
#sidebar-add-info {
  margin: 40px 0 15px;
  display: flex;
  flex-direction: column;
}
.add-info-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #E9E9E9;
  padding: 12px 0 5px;
}
.add-info-item h3 {
  font-size: 10px;
  font-weight: 400;
  line-height: 19px;
  color: #9B9B9B;
}
.add-info-item p {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: #595959;
}
#topics {
  flex-direction: column;
  border-bottom: none;
}
#sidebar-links {
  border-top: 1px solid #E0E0E0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 20px 0 30px;
}
.sidebar-link {
  padding: 6px 35px;
  font-weight: 400;
  font-size: 12px;
  color: #3d95cc;
  white-space: nowrap;
  cursor: pointer;
}
.sidebar-link:hover {
  background-color: #F7F7F7;
}
.sidebar-link > * {
  vertical-align: middle;
}
.sidebar-link img {
  height: 29px;
  width: 29px;
  margin-right: 13px;
}

@media screen and (max-width: 760px) {
}

#container {
  position: relative;
  width: 100%;
  margin-top: 20px;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #fff;
}

@media screen and (max-width: 760px) {
  #room-public-profile {
    flex-direction: column;
  }
  #sidebar-wrapper {
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (max-width: 400px) {
  #room-public-profile {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>

<style>
#room-public-profile #loader {
  height: 250px;
  position: relative;
}
</style>

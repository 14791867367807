<template>
  <div
    :data-subscription="subscription"
    :style="{ backgroundColor: subscriptionLevelBgColor }"
  >
    {{ subscription }} ROOM
  </div>
</template>

<script>
export default {
  name: "SubscriptionBadge",
  props: ['tier_id'],
  computed: {
    subscription() {
      return this.$CONSTANTS.getRoomSubscriptionParam(this.tier_id, 'name');
    },
    subscriptionLevelBgColor() {
      return this.$CONSTANTS.getRoomSubscriptionParam(this.tier_id, 'backgroundColor');
    }
  }
}
</script>

<style scoped>
div {
  display: inline-block;
  padding: 0 10px;
  border-radius: 20px;
  font-weight: 800;
  font-size: 10px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  text-transform: uppercase;
}

div[data-subscription="free"] {
  color: #595959;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
}
</style>

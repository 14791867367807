<template>
  <div class="menu-nickname">
    <image-a-level
      v-if="showAchievement"
      :alevel="alevel"
      type="web"
    />
    <p :style="{ color }" :title="nickname" v-html="nickname" />
  </div>
</template>

<script>
import ImageALevel from "@/components/ImageALevel";

export default {
  name: "SidebarNickname",
  components: {
    ImageALevel
  },
  props: {
    showAchievement: {
      type: Boolean,
      required: false,
      default: true
    },
    alevel: {
      type: Number,
      required: false,
      default: 0,
    },
    nickname: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: false,
      default: ''
    }
  },
}
</script>

<style scoped lang="scss">
.menu-nickname {
  position: relative;
  width: 100%;
  margin: 21px 0 6px;
  overflow: hidden;
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 100%;
    width: 45px;
    top: 0;
    right: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 */
  }

  img {
    margin-right: 14px;
  }

  p {
    color: #000;
    font-weight: 300;
    font-size: 30px;
    line-height: 40px;
    overflow: hidden;
    white-space: nowrap;
  }
}
</style>

<template>
  <div id="wrapper-input" :class="{ error: showError }">
    <label v-if="label">{{ label }}</label>
    <div id="input-inner" :class="{ disabled: disabled }" :data-icon="icon">
      <component :is="showIcon" />
      <input
        :type="type"
        :position="position"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :readonly="readonly"
        :disabled="disabled"
        :name="name"
        :value="value"
        :maxlength="max_length"
        @input="onChange"
        @focus="onFocus"
        @blur="onBlur"
        @keyup="onKeyUp"
        @keypress="onKeyPress"
      >
    </div>
    <span>
      <img src="@/assets/images/icons/error.svg">
      <span>{{ error }}</span>
    </span>
    <div v-if="length_counter && max_length > 0" class="input-counter">
      {{ valueLength }} / {{ max_length }}
    </div>
    <div v-if="info" id="input-info" v-html="info" />
  </div>
</template>

<script>
    export default {
        name: 'Input',
        props: {
            label: {
                type: String,
                required: false,
                default: '',
            },
            type: {
                type: String,
                required: false,
                default: 'text',
            },
            mode: {
                type: String,
                required: false,
                default: 'default',
            },
            position: {
                type: String,
                required: false,
                default: '',
            },
            placeholder: {
                type: String,
                required: false,
                default: '',
            },
            autocomplete: {
                type: String,
                required: false,
                default: 'off',
            },
            icon: {
                type: String,
                required: false,
                default: '',
            },
            name: {
                type: String,
                required: false,
                default: '',
            },
            required: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            readonly: {
              type: Boolean,
              required: false,
              default: false,
            },
            validate: {
                type: String,
                required: false,
                default: 'text',
            },
            value: {
                required: false,
                default: '',
            },
            min_value: {
                type: Number,
                required: false,
            },
            max_value: {
                type: Number,
                required: false,
            },
            max_length: {
                type: Number,
                required: false,
            },
            info: {
                type: String,
                required: false,
            },
            length_counter: {
              type: Boolean,
              required: false,
            },
        },
        data() {
            return {
                error: '',
                checkValue: (value) => {
                    if ( ! this.$helper.validateByRegexp(this.validate, value)) {
                        this.error = 'Value must be "' + this.validate + '"';
                        return;
                    }

                    if (this.required && !this.disabled && !value) {
                        this.error = 'This field is required';
                    }

                    if (this.validate === 'integer') {
                        if (this.min_value > value) {
                            this.error = 'Minimal value must be ' + this.min_value;
                        } else if (this.max_value < value) {
                            this.error = 'Maximal value must be ' + this.max_value;
                        }
                    } else {
                        if (this.max_length < value.length) {
                            this.error = 'Maximal length must be ' + this.max_length;
                        }
                    }
                },
            }
        },
        computed: {
            showError() {
                return !!this.error && !this.disabled;
            },
            showIcon() {
                if (this.icon) {
                    return() => import(`@/components/modules/input/icons/${this.icon}.vue`);
                }
                return '';
            },
            valueLength() {
              return this.value.length;
            }
        },
        methods: {
            hasError() {
                return this.error && !this.disabled;
            },
            onKeyUp(event) {
                if (this.disabled) {
                    return;
                }
                let i_code = (event.keyCode || event.charCode);

                this.$emit('key-up', event, i_code);
            },
            onKeyPress(event) {
                if (this.disabled) {
                    return;
                }
                let i_code = (event.keyCode || event.charCode);
                this.$emit('key-press', event, i_code);
            },
            onChange(event) {
                if (this.disabled) {
                    return;
                }
                let value = event.target.value;
                this.error = '';
                this.$emit('input', value);

                this.checkValue(value);

                if (this.mode === 'datepicker') {
                    this.$emit('input-picker', event);
                }
                this.$emit('change', value);
            },
            onFocus(event) {
                if (this.disabled) {
                    return;
                }
                this.error = '';

                if (this.mode === 'datepicker') {
                    this.$emit('show-picker', event);
                }

                this.$emit('focus');
            },
            onBlur(event) {
                if (this.disabled) {
                    return;
                }
                let element = event.target;

                if (this.mode !== 'datepicker') {
                    this.checkValue(element.value);
                }
                this.$emit('blur');
            },
        },
    }
</script>

<style scoped>
    #wrapper-input {
        position: relative;
        padding-bottom: 7px;
        font-family: 'Open Sans', sans-serif;
    }
    label {
        display: block;
        width: 100%;
        margin-bottom: 4px;
        padding-left: 10px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 10px;
        color: #858585;
        line-height: 14px;
    }
    #input-inner {
        background-color: #fff;
    }
    #input-inner.disabled {
        background-color: #e3e3e3;
        cursor: not-allowed;
    }

    #input-inner:not([data-icon=""]) svg {
        position: absolute;
        display: block;
        bottom: 18px;
        left: 15px;
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
        pointer-events: none;
        user-select: none;
    }

    #input-inner:not([data-icon=""]) input {
        padding-left: 46px;
    }

    input {
        display: block;
        height: 40px;
        width: 100%;
        padding: 0 40px 0 10px;
        border: 1px solid #ccc;
        border-radius: 3px;
        box-sizing: border-box;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #545454;
        background-color: inherit;
        outline: 0;
        transition: border-color .2s;
    }
    input:hover {
        border-color: #a5a5a5;
    }
    input:focus {
        border-color: #2ecb4e;
    }
    #wrapper-input[data-style="topic"],
    #wrapper-input[data-style="topic"] > #input-inner {
        height: 100%;
        padding: 0;
        background-color: transparent;
    }
    #wrapper-input[data-style="topic"] input {
        height: 100%;
        padding: 0 45px 0 41px;
        border: none;
        font-weight: 400;
        font-size: 16px;
        color: #16ca55;
        background-color: transparent;
        transition: .2s;
    }
    #input-inner.disabled > input {
        pointer-events: none;
    }
    input[position="center"] {
        padding-left: 40px;
        text-align: center;
    }
    #wrapper-input.error > #input-inner > input {
        border-color: #ff6b6b;
    }
    #wrapper-input > span {
        position: absolute;
        display: inline-block;
        right: 0;
        margin-top: 7px;
        opacity: 0;
        visibility: hidden;
        transition: .2s;
    }
    #wrapper-input.error > span {
        opacity: 1;
        visibility: visible;
    }
    #wrapper-input > span > img {
        position: absolute;
        display: block;
        height: 24px;
        width: 24px;
        top: -39px;
        right: 8px;
        padding: 5px;
        box-sizing: border-box;
    }
    #wrapper-input > span > span {
        position: relative;
        display: inline-block;
        padding: 10px 15px;
        border-radius: 3px;
        font-weight: 400;
        font-size: 12px;
        color: #545454;
        background-color: #d8d8d8;
        line-height: 17px;
        text-align: right;
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: .2s;
    }
    #wrapper-input > span > img:hover + span {
        opacity: 1;
        visibility: visible;
    }
    #input-info >>> a {
        color: #3d95cc;
        transition: .2s;
    }
    #input-info >>> a:hover {
        color: #3cafff;
    }
    #input-info >>> a:active {
        color: #0075c7;
    }
    .input-counter {
      font-size: 10px;
      line-height: 14px;
      text-align: left;
      margin: 5px;
    }
</style>

<template>
  <div id="topic">
    <span>{{ name }}</span>
    <button v-if="editable" @click="$emit('remove')">
      <img alt="remove-icon" src="@/assets/images/icons/remove-topic.svg">
    </button>
  </div>
</template>

<script>
export default {
  name: "Topic",
  props: ['name', 'editable']
}
</script>

<style scoped>
#topic {
  position: relative;
  display: inline-block;
  margin: 10px 10px 0 0;
  padding: 5px 10px;
  border-radius: 3px;
  box-sizing: border-box;
  font-size: 0;
  background-color: #eaf4ff;
  white-space: nowrap;
}
span {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  color: #545454;
  line-height: 16px;
}
button {
  position: relative;
  right: -3px;
  display: inline-block;
  padding: 5px;
  user-select: none;
  cursor: pointer;
  margin-left: 3px;
}
button img {
  height: 10px;
  width: 10px;
}
</style>

<template>
  <img
    :src="src"
    :alt="alt"
    :class="{ show: is_loaded }"
    :style="style"
    @load="setVisible"
  >
</template>

<script>
    export default {
        name: 'ImageFadeIn',
        props: {
            src: {
                type: String,
                required: false,
                default: '',
            },
            alt: {
                type: String,
                required: false,
                default: '',
            },
            height: {
                type: Number,
                required: false,
                default: 0,
            },
            width: {
                type: Number,
                required: false,
                default: 0,
            },
        },
        data() {
            return {
                is_loaded: false,
            }
        },
        computed: {
            style() {
                let style = {};

                if (this.height) {
                    style.height = this.height + 'px';
                }
                if (this.width) {
                    style.width = this.width + 'px';
                }
                return style;
            },
        },
        methods: {
            setVisible() {
                this.is_loaded = true;
            },
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    img {
        user-select: none;
        opacity: 0;
        visibility: hidden;
        transition: .2s;
    }
    img.show {
        opacity: 1;
        visibility: visible;
    }
</style>

<template>
  <div id="topic" :class="{ active: active, hidden: hidden }">
    <Input
      ref="topic"
      v-model="topic"
      data-style="topic"
      :placeholder="$t('hopping')"
      :disabled="disabled"
      @focus="focusTopic"
      @blur="blurTopic"
    />
    <svg v-if="!disabled"
         width="12"
         height="12"
         viewBox="0 0 12 12"
         xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#16ca55"
            fill-rule="nonzero"
            d="M2.077 12H0V9.923L8.152 1.77 10.23 3.85 2.076 12zm9.756-9.757L10.81 3.268 8.73 1.19 9.757.168c.222-.223.554-.223.774 0l1.303 1.302c.223.22.223.55 0 .773z"
      />
    </svg>
    <div v-if="!disabled" @click="sendTopic">
      <svg width="19"
           height="19"
           viewBox="0 0 19 19"
           xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="#40bd5e"
              fill-rule="nonzero"
              d="M1.397 18.877l16.19-8.545c.462-.245.64-.818.395-1.28-.09-.17-.227-.308-.396-.397L1.396.11C.935-.135.362.043.117.505c-.07.137-.11.288-.11.442L0 7.143c0 .15.105.28.25.31l8.286 1.73c.17.037.28.204.245.375-.025.123-.12.22-.244.245L.25 11.533c-.145.032-.25.16-.25.31l.007 6.197c0 .524.426.947.95.947.153 0 .304-.038.44-.11z"
        />
      </svg>
    </div>
    <span />
  </div>
</template>

<script>
import Input from '@/components/Input';
export default {
  name: "WhatsHopping",
  components: {
    Input
  },
  props: ['value', 'disabled'],
  data() {
    return {
      topic: this.value,
      active: false,
    }
  },
  computed: {
    hidden() {
      return !this.topic && this.disabled;
    }
  },
  methods: {
    focusTopic() {
      this.active = true;
    },
    blurTopic() {
      this.active = false;
      this.sendTopic();
    },
    sendTopic() {
      this.$emit('send-topic', this.topic);
    }
  }
}
</script>

<style scoped>

#topic {
  position: relative;
  height: 50px;
  width: 100%;
  border-radius: 4px;
  background-color: #c6f8cb;
  transition: .2s;
}

#topic:hover {
  background-color: #abffb4;
}

#topic.active {
  background-color: #fff;
}

#topic.hidden {
  visibility: hidden;
}

#topic > div ~ div {
  position: absolute;
  height: 40px;
  width: 40px;
  top: 5px;
  right: 5px;
  background-color: inherit;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  transition: .2s;
}

#topic > div ~ div > svg {
  vertical-align: middle;
}

#topic > div ~ div > svg > path {
  fill: #40bd5e;
  transition: .2s;
}

#topic > div ~ div:hover > svg > path {
  fill: #53ce70;
}

#topic > div ~ div:active > svg > path {
  fill: #30a14b;
}

#topic > svg {
  position: absolute;
  display: block;
  top: 19px;
  left: 21px;
  pointer-events: none;
}

#topic > span {
  position: absolute;
  height: 0;
  width: 0;
  top: 19px;
  left: -6px;
  border-width: 6px 6px 6px 0;
  border-style: solid;
  border-color: transparent #c6f8cb;
  transition: .2s;
}

#topic:hover > span {
  border-color: transparent #abffb4;
}

#topic.active > span {
  border-color: transparent #fff;
}

@media screen and (max-width: 760px) {
  #topic {
    margin: 20px 0;
  }
}
</style>
